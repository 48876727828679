import axios from "axios";
import { authHeader } from "@/services/auth-header";

let tempURL;
const domainParts = window.location.href.split("/");
if (domainParts[2].startsWith("localhost:")) {
  // if the app is served using Vue.js, it uses a different port for the backend
  tempURL = "http://localhost:" + 8001 + "/";
} else {
  tempURL = domainParts[0] + "//" + domainParts[2] + "/";
}

export const baseURL = tempURL;

const Api = axios.create({
  baseURL: baseURL + "api/",
  headers: {
    "Content-type": "application/json",
  },
});

export default {
  async get(addUrl) {
    return await Api.get(addUrl);
  },

  async getNextPostsforTopic(topic_id, limit, offset, startingFrom) {
    return Api.get(
      `nextPostsForTopic/${topic_id}?limit=${limit}&offset=${offset}&startingFrom=${startingFrom}`,
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async getNextPostsforCategory(category_name, limit, offset, startingFrom) {
    return Api.get(
      `nextPostsForCategory/${category_name}?limit=${limit}&offset=${offset}&startingFrom=${startingFrom}`,
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },
  async getNextPostsForParent(parent_id, limit, offset, startingFrom) {
    return Api.get(
      `nextPostsForParent/${parent_id}?limit=${limit}&offset=${offset}&startingFrom=${startingFrom}`,
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },

  async getNextPostsForSearch(query) {
    return Api.get(`nextPostsForSearch?${query}`, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getNextPostsFromUser(limit, offset) {
    return Api.get(`nextPostsFromUser?limit=${limit}&offset=${offset}`, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getNextSavedPosts(limit, offset) {
    return await Api.get(`nextSavedPostsForUser?limit=${limit}&offset=${offset}`, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getPostById(post_id) {
    return await Api.get(`postById/` + post_id, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getAllTopics() {
    return Api.get("allTopics", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getAllTopicNames() {
    return Api.get("allTopicNames", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getTopicByName(name) {
    return Api.get("topicByName/" + name);
  },

  async changeProfilePicture(Picture) {
    return Api.post("changeProfilePic", Picture, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },
  async getProfilePicture() {
    return (
      await Api.get("getProfilePic", { headers: { Authorization: `Bearer ` + authHeader() } })
    ).data;
  },

  async postNewPostcard(postcard) {
    return Api.post("postNewPostcard", postcard, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async postNewAnswer(postcard) {
    return Api.post("postNewAnswer", postcard, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async updateAndApprovePostcard(postcard) {
    return Api.post("updateAndApprovePostcard/", postcard, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async updateTopicInformation(topic_id, text) {
    return Api.patch(
      "updateTopicInformation/" + topic_id,
      { text: text },
      {
        headers: { Authorization: `Bearer ` + authHeader() },
      }
    );
  },

  async hidePostcard(postcard) {
    return Api.post("hidePostcard/", postcard, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async postNewTopic(name) {
    return Api.post("postNewTopic", { name: name });
  },

  async setTopicPinned(topicId) {
    return Api.get("setTopicPinned/" + topicId, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async setPostSaved(postId) {
    return Api.get("savePost/" + postId, { headers: { Authorization: `Bearer ` + authHeader() } });
  },

  async changeProfileData(form) {
    //Ich weiß, dass die Anfrage absolut sinnlos ist, aber eslint hat gemotzt, dass ich die Variablen nicht nutze
    return await Api.post(`changeProfileData`, form, {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },
  async getUserProfile() {
    return Api.get("getProfileData", {
      headers: { Authorization: `Bearer ` + authHeader() },
    });
  },

  async getTextByName(name) {
    return Api.get("textByName/" + name);
  },
};
