import Vue from "vue";
import VueRouter from "vue-router";
import ForumSearch from "@/views/ForumSearch";
import CreatePostcard from "@/views/CreatePostcard";
import Success from "@/views/Success";
import Start from "@/views/Start";
import Login from "@/views/Login";
import Register from "@/views/Register";
import Help from "@/views/Help";
import Veranstaltungen from "@/views/Veranstaltungen";
import HistoryHubland from "@/views/HistoryHubland";
import SinglePostcard from "@/views/SinglePostcard";
import { getLoggedInUser } from "@/services/loggedIn";
import Test from "@/views/Test";
import Forum from "@/views/Forum";
import TermsOfUse from "@/views/TermsOfUse";
import Impressum from "@/views/Impressum";
import DataPrivacy from "@/views/DataPrivacy";
import authApi from "@/services/auth-api";
import Administration from "@/views/Administration";
import aboutUs from "@/views/AboutUs";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: Start,
    meta: {
      title: "Hubbel - Startseite",
    },
  },
  {
    path: "/about",
    name: "About",
    component: aboutUs,
    meta: {
      title: "Über uns",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
  },
  {
    path: "/nutzungsbedingungen",
    name: "Nutzungsbedingungen",
    component: TermsOfUse,
    meta: {
      title: "Nutzungsbedingungen",
    },
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    meta: {
      title: "Impressum",
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: DataPrivacy,
    meta: {
      title: "Datenschutz",
    },
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
    meta: {
      title: "Hilfe",
    },
  },
  {
    path: "/veranstaltungen",
    name: "Veranstaltungen",
    component: Veranstaltungen,
    meta: {
      title: "Veranstaltungen",
    },
  },
  {
    path: "/das-hubland",
    name: "HistoryHubland",
    component: HistoryHubland,
    meta: {
      title: "HistoryHubland",
    },
  },
  {
    path: "/createPostcard",
    name: "Postkarte erstellen",
    component: CreatePostcard,
    meta: {
      title: "Postkarte erstellen",
      authRequired: true,
    },
  },
  {
    path: "/createPostcard/:id",
    name: "createPostcard",
    component: CreatePostcard,
    meta: {
      title: "Postkarte erstellen",
      authRequired: true,
    },
  },
  {
    path: "/success",
    name: "Gesendet",
    component: Success,
    meta: {
      title: "Gesendet",
    },
  },
  {
    path: "/forumsuche",
    name: "ForumSuche",
    component: ForumSearch,
    meta: {
      title: "Forum - Suche",
    },
  },
  {
    path: "/forum",
    name: "ForumGesamt",
    component: Forum,
    meta: {
      title: "Forum",
    },
  },
  {
    path: "/forum/:topic",
    name: "forum_view",
    component: Forum,
    meta: {
      title: "Forum",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "@/views/Profile.vue"),
    meta: {
      title: "Mein Profil",
      authRequired: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Anmelden",
    },
  },
  {
    path: "/register",
    name: "Registrieren",
    component: Register,
    meta: {
      title: "Registrieren",
    },
  },
  {
    path: "/topics",
    name: "Themen",
  },
  {
    path: "/Post/:post_id",
    name: "Post",
    component: Forum,
    meta: {
      title: "Shared Post",
    },
  },
  {
    path: "/Postcard/:postcard_id",
    name: "Postkarte",
    component: SinglePostcard,
    meta: {
      title: "Postkarte",
    },
  },
  {
    path: "/Test",
    name: "Test",
    component: Test,
    meta: {
      title: "Test",
    },
  },
  {
    path: "/Administration",
    name: "Administration",
    component: Administration,
    meta: {
      title: "Administration",
      authRequired: true,
      PermissionLevelRequirement: "admin",
    },
  },
  {
    path: "/Moderation",
    name: "Moderation",
    component: Administration,
    meta: {
      title: "Moderation",
      authRequired: true,
      PermissionLevelRequirement: "moderator",
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  //change Title
  document.title = to.meta.title || "Hubbel";

  //authentication
  if (to.meta.authRequired) {
    if (getLoggedInUser() === null) {
      next({ name: "Login" });
    } else if (to.meta.PermissionLevelRequirement) {
      console.log(
        to.meta.PermissionLevelRequirement + " == " + JSON.parse(getLoggedInUser()).level_of_user
      );
      authApi.verifyPermissions(to.meta.PermissionLevelRequirement).then((res) => {
        if (res.data.approved) {
          next();
        } else next({ name: "Start" });
      });
    } else next();
  } else next();
});

export default router;
