<template>
  <div class="forum-view forum-view--search">
    <div class="forum-container">
      <div
        id="forum__head"
        class="forum__head shadow color-white"
        :class="{
          'forum__head--collapsed': forum__head_isCollapsed,
        }"
      >
        <div
          :class="{
            'heading-one': !forum__head_isCollapsed,
            'heading-two': forum__head_isCollapsed,
          }"
        >
          Postkartensuche
        </div>
      </div>

      <div class="forum__content">
        <v-container
          v-if="Object.values(search_params).every((val) => val === null || val.length === 0)"
          class="feed-comp"
        >
          <div class="postcard-search-hint">
            Um Postkarten zu suchen, gib rechts einen Suchbegriff und/oder einen Autor ein.
          </div>
        </v-container>
        <v-container
          v-if="
            Object.values(search_params).some((val) => val?.length > 0) && postcards.length === 0
          "
          class="feed-comp"
        >
          <div class="postcard-search-hint">
            Es scheint keine Postkarte mit deinen Suchparametern zu geben.<br />
            Falls du selbst Informationen zu diesem Thema hast, schreib doch gerne selbst eine
            Postkarte! 😊
          </div>
        </v-container>

        <Feed
          v-if="
            Object.values(search_params).some((val) => val === null || val.length > 0) &&
            postcards.length >= 0
          "
          id="forum__content"
          ref="feed"
          :class="{ 'd-none': postcards.length === 0 }"
          :postcards="postcards"
          :isEndReached="isEndReached"
          @scroll="handleScroll"
        />

        <div class="feed__info-btn">
          <Button
            class="info-btn__button color-primary"
            :icon="feed__info_isCollapsed ? 'chevronLeft' : 'chevronRight'"
            label_text=""
            is-cross
            @click.native="toggleBgInfo"
          />
          <p class="info-btn__heading" :class="{ 'd-none': !feed__info_isCollapsed }">
            Sucheinstellungen
          </p>
        </div>

        <div
          class="feed__info color-light-grey"
          :class="{
            'feed__info--collapsed': feed__info_isCollapsed,
          }"
        >
          <p class="heading-three">Sucheinstellungen:</p>
          <div>
            <div class="search-form">
              <p>
                Hier werden Dir alle Postkarten angezeigt, die die von Dir eingegebenen Suchbegriffe
                enthalten. Beachte, dass die Suchbegriffe immer zusammengesetzt gesucht werden.
                Suchst Du nach 'Hubland Center' wird also weder 'Hubland treff' noch 'Garten Center'
                gefunden.
              </p>
              <v-text-field v-model="search_params_new.searchTerm" label="Suchbegriff" />
              <v-text-field v-model="search_params_new.searchAuthor" label="Autor" />
              <v-row class="ml-0">
                <v-flex xs12 md6>
                  <v-menu v-model="showStartDatePicker" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="search_params_new.searchStartDate"
                        label="Nach"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="search_params_new.searchStartDate"
                      no-title
                      @input="showStartDatePicker = false"
                      @click.stop=""
                    />
                  </v-menu>
                  <v-btn icon class="mt-4" @click="resetStartDate">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6>
                  <v-menu v-model="showEndDatePicker" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="search_params_new.searchEndDate"
                        label="Vor"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="search_params_new.searchEndDate"
                      no-title
                      @input="showEndDatePicker = false"
                      @click.stop=""
                    />
                  </v-menu>
                  <v-btn icon class="mt-4" @click="resetEndDate">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-flex>
              </v-row>
              <Button
                :is-icon="true"
                icon="searchForPostcard"
                label_text="Postkarten suchen!"
                @click.native="search()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <Button
      v-if="showWritePostcard"
      icon="writePostcard"
      label_text="Postkarte schreiben"
      :is-icon="true"
      class="writePostcard"
      @click.native="$router.push({ name: 'createPostcard' }), sendMatomoEvent()"
      @click="updateNav()"
    />
  </div>
</template>

<script>
import Feed from "@/components/Feed";
import Button from "@/components/Button";
import API from "@/services/api";
import { getLoggedInUser } from "@/services/loggedIn";
import { toBoolean } from "../../../client-hubbel/src/services/utils";
import config from "../../../options.json";

export default {
  name: "ForumView",
  components: {
    Button,
    Feed,
  },
  data: () => ({
    topic: {},
    postcards: [],
    search_params_new: {
      searchTerm: "",
      searchAuthor: "",
      searchStartDate: null,
      searchEndDate: null,
    },
    showStartDatePicker: false,
    showEndDatePicker: false,
    search_params: {
      searchTerm: "",
      searchAuthor: "",
      searchStartDate: null,
      searchEndDate: null,
    },
    forum__head_isCollapsed: false,
    feed__info_isCollapsed: false,
    postData: null,
    isEndReached: false,
  }),
  computed: {
    topicInformationTransformed() {
      return {
        template: this.topic.information,
        props: this.$options.props,
      };
    },
    showWritePostcard() { return (this.loggedIn() || config.client.WritePostcardButtonNotLoggedIn)},
  },

  beforeMount() {
    this.initialize();
  },

  created() {
    this.$watch(
      () => this.$route.query,
      () => this.initialize()
    );
  },

  mounted() {
    document.getElementById("forum__content").addEventListener("scroll", this.handleScroll);
    this.feed__info_isCollapsed = window.innerWidth <= 960; // collapse on mobile
  },

  beforeDestroy() {
    document.getElementById("forum__content").removeEventListener("scroll", this.handleScroll);
    //TODO: https://stackoverflow.com/questions/68588188/can-not-get-element-by-getelementbyid-in-beforedestroy-lifecycle-vue
  },
  methods: {
    sendMatomoEvent() {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      window._paq.push([
        "trackEvent",
        "CreatePostcard on hubbel",
        "Click 'Write Postcard' Button",
        "'Write Postcard' clicked.",
      ]);
    },
    initialize() {
      (async () => {
        this.postcards = []; // Make empty to allow for new initial postcards
        this.topic.information = null; // not used in ForumSearch

        if (Object.keys(this.$route.query).length > 0) {
          // fill search_params to get initial posts
          this.search_params["searchTerm"] = this.$route.query.searchTerm || "";
          this.search_params["searchAuthor"] = this.$route.query.searchAuthor || "";
          this.search_params["searchStartDate"] = this.$route.query.searchStartDate || "";
          this.search_params["searchEndDate"] = this.$route.query.searchEndDate || "";

          // fill search_params_new to show latest search params in user input fields
          this.search_params_new["searchTerm"] = this.$route.query.searchTerm || "";
          this.search_params_new["searchAuthor"] = this.$route.query.searchAuthor || "";
          this.search_params_new["searchStartDate"] = this.$route.query.searchStartDate || "";
          this.search_params_new["searchEndDate"] = this.$route.query.searchEndDate || "";
          this.getInitialPosts();
        } else {
          // show text for no search term
          // window.location.href = "/Forum";
        }
      })();
    },
    loggedIn() {
      return getLoggedInUser() !== null;
    },
    updateNav() {
      this.$emit("update_Nav");
    },
    resetStartDate() {
      this.search_params_new.searchStartDate = null;
    },
    resetEndDate() {
      this.search_params_new.searchEndDate = null;
    },
    search() {
      const path = "/forumsuche";
      this.search_params = this.search_params_new;
      const query = this.search_params;
      this.$router.push({ path, query });
      // const path = `/Forumsuche/${this.new_search_term}`;
      // if (this.$route.path !== path) this.$router.push(path);
      // https://stackoverflow.com/questions/57837758/navigationduplicated-navigating-to-current-location-search-is-not-allowed
    },
    handleScroll() {
      //handle topbar collapse
      const forum__head = document.getElementById("forum__head");
      let forum__content = document.getElementById("forum__content");
      if (this.postcards.length > 1) {
        // kann man bestimmt schöner Lösen, weiß aber gerade nicht wie
        if (forum__content.scrollTop > 100) this.forum__head_isCollapsed = true;
        else if (forum__head.style.height !== "200px") this.forum__head_isCollapsed = false;
      }
      //load more posts
      if (!this.$route.params.post_id) {
        if (
          forum__content.scrollTop + forum__content.clientHeight >=
          forum__content.scrollHeight * 0.75
        ) {
          const options = {
            limit: 3,
            offset: this.postcards.length,
            startingFrom: this.postcards[0].id,
          };
          const query = Object.entries({ ...options, ...this.search_params })
            .map(([key, value]) => {
              return encodeURIComponent(key) + "=" + encodeURIComponent(value);
            })
            .join("&");
          API.getNextPostsForSearch(query).then((res) => {
            res.data.forEach((postObj) => this.renderPost(postObj));
            this.isEndReached = res.data.length <= 0;
          });
        }
      }
    },

    toggleBgInfo() {
      this.feed__info_isCollapsed = !this.feed__info_isCollapsed;
    },
    async start() {},
    renderPost(postObj) {
      if (this.postcards.some((e) => e.id === postObj.post_id)) {
        return;
      }

      function getSender() {
        if (postObj.sender_name) {
          return postObj.sender_name;
        } else if (postObj.user.team && postObj.user.team.length > 0) {
          return postObj.user.team;
        } else if (postObj.user.last_name.length <= 0) {
          return postObj.user.first_name;
        } else {
          return postObj.user.first_name + " " + Array.from(postObj.user.last_name)[0] + ".";
        }
        //TODO maybe keep full name here for moderators
      }

      this.postcards.push({
        id: postObj.post_id,
        readOnly: true,
        approved: postObj.approved,
        information: postObj.information,
        category: postObj.category,
        sender_verified: postObj.user.verified,
        sender_team: postObj.user.team,
        sender: getSender(),
        subject: postObj.topic.name,
        allTopicNames: postObj.allTopicNames,
        text: postObj.text,
        date: postObj.createdAt.split("T")[0] + " " + postObj.createdAt.split(/[T.]/)[1],
        attachments: postObj.Attachments,
        isSaved: postObj.isSaved,
        sentFrom: postObj.sentFrom,
        show: false,
      });
    },

    getInitialPosts() {
      const options = { limit: 5, offset: 0, startingFrom: -1 };
      const query = Object.entries({ ...options, ...this.search_params })
        .map(([key, value]) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(value);
        })
        .join("&");
      API.getNextPostsForSearch(query).then((res) =>
        res.data.forEach((postObj) => this.renderPost(postObj))
      );
    },
  },
};
</script>
