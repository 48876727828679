<template>
  <div class="text-left">
    <v-card v-if="editorIsOpen" elevation="24" tile style="z-index: 100;">
      <v-card-title>
        <span class="text-h5">Text bearbeiten</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="editedName"
                disabled
                :label="table.headers[1].text"
              />
            </v-col>
            <v-col cols="12">
              <ckeditor :editor="editor" v-model="editedContent" :config="editorConfig" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancelEditor"> Abbrechen </v-btn>
        <v-btn text color="info" @click="saveEditedItem"> Speichern </v-btn>
      </v-card-actions>
    </v-card>
    <v-data-table v-else
      hide-default-footer
      :headers="table.headers"
      :items="texts"
    >
      <template v-slot:item="{ item }">
        <tr
          @click="selected_text = item"
        >
          <td class="text-right">{{ item.text_id }}</td>
          <td>{{ item.name }}</td>
          <td>
            <v-icon small @click.stop="openEditor(item)">
              mdi-pencil
            </v-icon>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AdminApi from "@/services/admin-api";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../../../options.json";

export default {
  name: "TextOptions",
  components: {},
  data: () => ({
    table: {
      headers: [
        {
          text: "id",
          value: "text_id",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "start",
        },
        {
          text: "Aktionen",
          sortable: false,
        },
      ],
    },
    selected_text: null,
    texts: [],
    editorIsOpen: false,
    editedName: null,
    editedContent: null,
    editor: ClassicEditor,
    editorConfig: config.client.editor,
  }),
  mounted() {
    this.getAllTexts();
  },
  methods: {
    getAllTexts() {
      AdminApi.getAllTexts().then((res) => {
        res.data.forEach((text) => this.texts.push(text));
      });
    },
    updateTextContent(text_id, text) {
      return AdminApi.updateTextContent(text_id, text)
    },
    openEditor(item) {
      this.selected_text = item
      this.editedName = item.name
      this.editedContent = item.content
      this.editorIsOpen = true
    },
    cancelEditor() {
      this.editedName = null
      this.editedContent = null
      this.editorIsOpen = false
    },
    async saveEditedItem() {
      if (this.editedContent !== this.selected_text.content) {
        await this.updateTextContent(this.selected_text.text_id, this.editedContent)
        this.selected_text.content = this.editedContent
      }

      this.editorIsOpen = false
    },
  },
};
</script>

<style scoped></style>
