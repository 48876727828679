var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{staticClass:"shadow postcard-comp",attrs:{"action":""}},[_c('div',{staticClass:"postcard__wrapper"},[(!_vm.attachmentShown)?_c('div',{staticClass:"postcard__front"},[_c('div',{staticClass:"postcard__right"},[_c('div',{staticClass:"postcard__date"},[_c('ul',[_c('li',[_vm._v(" "+_vm._s(_vm.getDateString(_vm.postcard_info.date))+" ")]),_c('li',[(
                  _vm.postcard_info.readOnly &&
                  ['PC', 'Phone', 'Mailbox'].includes(_vm.postcard_info.sentFrom)
                )?_c('v-img',{staticClass:"date__img date__img--stamp",attrs:{"alt":'Created from ' + _vm.postcard_info.sentFrom,"contain":"","src":require(`../../../client-shared/img/stamp_${_vm.postcard_info.sentFrom.toLowerCase()}.svg`)}}):_vm._e(),_c('v-img',{staticClass:"date__img date__img--logo",attrs:{"alt":"hubbel Logo","contain":"","src":require("../../../client-shared/img/hubbel_logo.svg")}})],1)])]),(
            _vm.loggedInUser != null &&
            _vm.loggedInUser.level_of_user !== 'normal' &&
            !_vm.postcard_info.readOnly
          )?_c('div',{staticClass:"postcard__moderation"},[_c('v-btn',{staticClass:"approvePost",attrs:{"elevation":"1","outlined":""},on:{"click":function($event){return _vm.approve(_vm.postcard_info)}}},[_vm._v("freischalten")]),_c('v-btn',{staticClass:"deletePost",attrs:{"elevation":"1","outlined":""},on:{"click":function($event){return _vm.hide(_vm.postcard_info)}}},[_vm._v("löschen")])],1):_vm._e(),(
            _vm.loggedInUser != null &&
            _vm.loggedInUser.level_of_user !== 'normal' &&
            _vm.postcard_info.readOnly &&
            _vm.postcard_info.approved
          )?_c('div',{staticClass:"postcard__moderation"},[_c('v-btn',{staticClass:"editPost",attrs:{"elevation":"1","outlined":""},on:{"click":function($event){return _vm.makePostcardEditable()}}},[_vm._v("jetzt prüfen")])],1):_vm._e(),_c('div',{staticClass:"postcard__sender"},[_c('ul',[_c('li',[_vm._v("Absender*in")]),_c('li',[(
                  _vm.postcard_info.readOnly ||
                  _vm.postcard_info.topicFixed ||
                  _vm.postcard_info.sentFrom !== 'Mailbox'
                )?_c('div',[_c('b',{class:'verified--' + _vm.postcard_info.sender_verified},[_vm._v(_vm._s(_vm.postcard_info.sender)+" "),(_vm.postcard_info.sender_verified)?_c('v-img',{staticClass:"verified-icon",attrs:{"src":require("../../../client-shared/img/verification_hubbel_v01.svg"),"alt":"(verified)","width":"18","tabindex":"1"}}):_vm._e()],1)]):_c('div',{staticClass:"selectQuestion"},[_c('v-text-field',{attrs:{"dense":"","clearable":""},model:{value:(_vm.senderName),callback:function ($$v) {_vm.senderName=$$v},expression:"senderName"}})],1)])])]),_c('div',{staticClass:"postcard__subject"},[_c('ul',[_c('br'),_c('li',[_vm._v("Thema:")]),_c('li',[(_vm.postcard_info.readOnly || _vm.postcard_info.topicFixed)?_c('div',[_c('router-link',{staticClass:"subject__link",attrs:{"to":`/Forum/${_vm.postcard_info.subject}`}},[_c('b',[_vm._v(_vm._s(_vm.postcard_info.subject))])])],1):_c('div',{staticClass:"selectQuestion"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedTopic),expression:"selectedTopic"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedTopic=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.possibleTopics),function(topic){return _c('option',{key:topic.topic_id,attrs:{"title":topic.name},domProps:{"value":topic.topic_id}},[_vm._v(" "+_vm._s(topic.name.substr(0, 15) + (topic.name.length > 15 ? "..." : "") /*TODO: A line-break would be preferred here*/)+" ")])}),0)])])])])]),_c('div',{staticClass:"postcard__vertical-line"}),_c('div',{staticClass:"postcard__left"},[_c('div',[(_vm.postcard_info.readOnly)?_c('p',{staticClass:"postcard__message font-regular",class:{
              'postcard__message--moderationAdjustment':
                _vm.loggedInUser != null && _vm.loggedInUser.level_of_user !== 'normal',
            }},[(
                _vm.postcard_info.approved ||
                (_vm.loggedInUser != null && _vm.postcard_info.sender === _vm.fullName(_vm.loggedInUser))
              )?_c('span',[_vm._v(_vm._s(_vm.postcard_info.text)+" ")]):_c('span',{staticClass:"dummyText"},[_vm._v(" "+_vm._s(_vm.dummyText)+" ")])]):_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.writtenText),expression:"writtenText"}],staticClass:"postcard__message postcard__message--editable font-regular",attrs:{"name":"message","maxlength":"500","rows":"12","cols":"40","placeholder":_vm.postcard_info.text.length > 0
                  ? _vm.postcard_info.text
                  : 'Inhalt (maximal 500 Zeichen)'},domProps:{"value":(_vm.writtenText)},on:{"input":function($event){if($event.target.composing)return;_vm.writtenText=$event.target.value}}}),_vm._v(" "),_c('v-icon',{staticClass:"message__paper-clip",on:{"click":function($event){return _vm.openInput()}}},[_vm._v(" $paperclip ")]),_c('span',{staticClass:"message__text-length"},[_vm._v(_vm._s(_vm.writtenText.length)+"/500")])],1)])])]):_vm._e(),(_vm.attachmentShown)?_c('div',{staticClass:"postcard__back"},[_c('Attachment',{attrs:{"attachments":_vm.attachments,"postcard_id":_vm.postcard_info.id,"read-only":_vm.postcard_info.readOnly},on:{"deleteAttachment":_vm.deleteAttachment,"openInput":function($event){return _vm.openInput()}}})],1):_vm._e()]),(!_vm.postcard_info.readOnly || (_vm.attachments.length > 0 && _vm.postcard_info.approved))?_c('Button',{staticClass:"postcard__attachment-switcher",attrs:{"id":"attachmentSwitcher","is-anhang":true,"icon":"paperclip","label_text":_vm.attachmentShown
        ? 'Postkarte anzeigen'
        : _vm.attachments.length === 0
        ? 'Anhang hinzufügen'
        : _vm.attachments.length + (_vm.attachments.length === 1 ? ' Anhang' : ' Anhänge')},nativeOn:{"click":function($event){return _vm.toggleAttachment($event)}}}):_vm._e(),(!_vm.postcard_info.readOnly)?_c('input',{staticClass:"fileChooser",attrs:{"id":"fileChooser","type":"file","accept":"image/*,.pdf","multiple":""},on:{"change":function($event){return _vm.uploadAttachments()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }