<template>
  <v-stepper v-model="e1" alt-labels class="stepper elevation-0 progress-bar-comp">
    <div class="progress-bar__header-wrapper">
      <v-stepper-header class="progress-bar__header">
        <v-stepper-step :complete="e1 > 1" step="1" :editable="e1 > 1"> Thema </v-stepper-step>

        <v-divider />

        <!--<v-stepper-step :complete="e1 > 2" step="2" :editable="e1 > 2"> Kategorie </v-stepper-step>

        <v-divider />-->

        <v-stepper-step :complete="e1 > 2" step="2" :editable="e1 > 2">
          Beitrag schreiben
        </v-stepper-step>

        <v-divider />

        <v-stepper-step step="3"> Beitrag prüfen </v-stepper-step>
      </v-stepper-header>
    </div>
    <v-stepper-items class="progress-bar__content">
      <v-stepper-content step="1" class="content__step--topic">
        <h2 class="content__heading">Zu welchem Thema passt dein Beitrag am besten?</h2>

        <div class="content__topic-grid">
          <!-- TODO: Hard-coded 7 should be replaced with env -->
          <DashedCard
            v-if="withoutTopicId >= 0"
            title="Ohne Thema"
            :selected="selectedTopic.id === withoutTopicId"
            @click.native="
              selectedTopic = { id: withoutTopicId, title: 'Ohne Thema', approved: false };
              toSecondStep();
              sendMatomoEvent(1);
            "
          />
          <DashedCard
            v-if="allowNewTopics"
            title="Neues Thema vorschlagen"
            icon="$plus"
            @click.native="openNewTopicDialog()"
          />
          <Card
            v-for="topic in getApprovedTopics()"
            :id="topic.id"
            :key="topic.id"
            :v-if="topic.approved === true"
            :title="topic.title"
            :no-pin="true"
            :no-last-post="true"
            :selected="selectedTopic?.id === topic.id || false"
            @click.native="
              selectedTopic = topic;
              toSecondStep();
              sendMatomoEvent(1);
            "
          />
        </div>

        <!-- Modal for requesting a new topic TODO: put into separate component -->
        <div id="newTopicModal" class="modal">
          <div id="enterTopic" class="modal-content">
            <span class="close" @click="closeNewTopicDialog()"><v-icon>$x</v-icon></span>
            <p class="heading-three font-dark-grey modal-heading">Neues Thema vorschlagen</p>
            <p class="font-dark-grey font-medium modal-text text-left">
              Wie soll das neue Thema heißen?
            </p>
            <v-text-field
              ref="newTopic"
              v-model="newTopic"
              placeholder="Neues Thema"
              label="Thema"
              input-id="newTopic"
              :rules="[
                (v) => {
                  return !!v;
                },
              ]"
            />
            <Button label_text="Absenden" :is-primary="true" @click.native="sendNewTopic()" />
          </div>
          <div id="infoAndNext" class="modal-content" style="display: none">
            <span class="close" @click="closeNewTopicDialog()"><v-icon>$x</v-icon></span>
            <p class="heading-three font-dark-grey modal-heading">Neues Thema vorschlagen</p>
            <p class="font-dark-grey font-medium modal-text">
              Danke für deinen Themenvorschlag. Er wurde an die Redaktion gesendet und wird in Kürze
              bearbeitet.
            </p>
            <Button
              label_text="Weiter"
              :is-primary="true"
              @click.native="
                closeNewTopicDialogAndGoToTopic();
                sendMatomoEvent(0);
                sendMatomoEvent(1);
              "
            />
          </div>
        </div>
        <!-- END Modal for requesting a new topic -->

        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="$router.push('/')" />
          <Button
            :is-primary="true"
            label_text="Weiter"
            :disabled="selectedTopic.id !== null ? false : 'disabled'"
            class="button-row__next"
            @click.native="
              toSecondStep();
              sendMatomoEvent(1);
            "
          />
        </div>
      </v-stepper-content>

      <!--<v-stepper-content step="2" class="content__step--category">
        <h2 class="content__heading">Zu welcher Kategorie gehört Ihr Beitrag?</h2>
        <div class="content__category-grid">
          <Card v-for="categoryName in getCategories()"
                :title="categoryName"
                :key="categoryName"
                :is-info="true"
                :no-last-post="true"
                :no-pin="true"
                :selected="category === categoryName"
                 class="category-grid__item"

                @click.native="
      category = categoryName;
      toThirdStep();
      sendMatomoEvent(2);
    " />
        </div>

        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="e1 = 1" />
        </div>
      </v-stepper-content>
        -->

      <v-stepper-content step="2" class="content__step--text">
        <PostCard ref="editPostcard"
                  :postcard_info="Object.assign({ readOnly: false, topicFixed: true }, PostCard_Info)"
                  class="content__postcard"
                  @writtenTextChanged="updateText" />

        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="e1 = 1" />
          <Button :is-primary="true"
                  label_text="Weiter"
                  :disabled="text.length > 0 ? false : 'disabled'"
                  class="button-row__next"
                  @click.native="
              toThirdStep();
              sendMatomoEvent(3);
            " />
        </div>
      </v-stepper-content>

      <v-stepper-content step="3" class="content__step--check">
        <!-- assign "approved=true" here to display the postcard as if it was approved -->
        <PostCard
          ref="verifyPostcard"
          :postcard_info="Object.assign({ readOnly: true, approved: true }, PostCard_Info)"
          class="content__postcard"
        />

        <div class="content__button-row">
          <Button :is-back="true" class="button-row__back" @click.native="e1 = 2" />
          <Button
            :is-icon="true"
            :icon="this.isSubmitting ? 'mdi-email-fast-outline' : 'mdi-email-fast'"
            label_text="Absenden"
            class="button-row__next"
            :disabled="this.isSubmitting ? 'disabled' : false"
            @click.native="
              sendCard();
              sendMatomoEvent(4);
            "
          />
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import DashedCard from "./DashedCard";
import Card from "./Card";
import Button from "./Button";
import PostCard from "./PostCard";
import Api from "@/services/api.js";
import { getLoggedInUser } from "@/services/loggedIn";
import { toBoolean } from "../../../client-hubbel/src/services/utils";

export default {
  name: "Progressbar",
  components: {
    PostCard,
    Button,
    Card,
    DashedCard,
  },
  props: {
    topicId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      e1: 1,
      newTopic: "",
      selectedTopic: { id: null, title: null, approved: null },
      category: null,
      text: "",
      attachments: [],
      dialog: false,
      topics: [],
      user: null,
      isSubmitting: false,
      withoutTopicId: process.env.VUE_APP_WITHOUT_TOPIC_ID || -1, // database id for when no specific topic is selected
      allowNewTopics: toBoolean(process.env.VUE_APP_ALLOW_NEW_TOPICS),
    };
  },

  computed: {
    PostCard_Info() {
      return {
        information: this.category === "information",
        category: this.category,
        sender: this.getSender(this.user),
        sender_verified: this.user.verified,
        subject: this.getTitle(),
        text: this.text,
        attachments: this.attachments,
        date: this.getCurrentDate(),
        sentFrom: "PC",
      };
    },
  },
  created() {
    this.getTopics();
    let tempUser = getLoggedInUser();
    if (tempUser) {
      this.user = JSON.parse(tempUser);
    }
  },
  methods: {
    updateText(writtenText) {
      this.text = writtenText;
    },
    sendMatomoEvent(step) {
      if (!toBoolean(process.env.VUE_APP_USE_MATOMO)) return;
      switch (step) {
        case 0:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on Website",
            "Click Next Button",
            "Step 0: New Topic proposed",
          ]);
          break;
        case 1:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on Website",
            "Click Next Button",
            "Step 1: Card Topic selected",
          ]);
          break;
        case 2:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on Website",
            "Click Next Button",
            "Step 2: Card Type selected",
          ]);
          break;
        case 3:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on Website",
            "Click Next Button",
            "Step 3: Card Content added",
          ]);
          break;
        case 4:
          window._paq.push([
            "trackEvent",
            "CreatePostcard on Website",
            "Click Submit Button",
            "Step 4: Card submitted",
          ]);
          break;
        default:
          break;
      }
    },
    getSender(user) {
      if (user.team && user.team.length > 0) {
        return user.team;
      } else if (user.last_name.length <= 0) {
        return user.first_name;
      } else {
        return user.first_name + " " + Array.from(user.last_name)[0] + ".";
      }
    },
    getTitle() {
      return this.selectedTopic.title;
    },
    getTopics() {
      Api.getAllTopics().then((data) => {
        data.data.forEach((topicObj) => {
          this.addTopic(topicObj);
        });
        this.selectTopic();
      });
    },
    addTopic(topicObj) {
      this.topics.push({
        id: topicObj.topic_id,
        title: topicObj.name,
        approved: topicObj.approved,
        pinned: false,
        date:
          topicObj.Posts && topicObj.Posts.length !== 0
            ? topicObj.Posts[0].createdAt
            : topicObj.createdAt,
      });
    },
    selectTopic() {
      if (this.topicId) {
        this.topics.forEach((topic) => {
          if (topic.id === this.topicId) {
            this.selectedTopic = topic;
          }
        });
      }
    },
    getApprovedTopics() {
      let approvedTopics = [];
      this.topics.forEach((topic) => {
        if (topic.approved) {
          approvedTopics.push(topic);
        }
      });

      approvedTopics.sort((a, b) => {
        if (this.selectedTopic?.id === a.id) return -1;
        if (this.selectedTopic?.id === b.id) return 1;
        return a.title.localeCompare(b.title);
      });

      return approvedTopics;
    },

    getCategories() {
      let categories = new Array("Information", "Question", "Event");
      return categories;
    },

    toSecondStep() {
      if (this.selectedTopic.id === null) {
        alert("Bitte wähle ein Thema!");
      } else {
        this.e1 = 2;
      }
    },
    /* there used to be a third step fr chosing a category -> can be deleted
    toThirdStep() {
      if (this.category === null) {
        alert("Bitte wähle eine Kategorie!");
      } else {
        this.e1 = 3;
      }
    },*/

    toThirdStep() {
      this.e1 = 3;
      this.text = this.$refs.editPostcard.writtenText;
      this.attachments = this.$refs.editPostcard.attachments;
      this.$refs.verifyPostcard.pushRenderedAttachments(this.attachments);
    },
    sendCard() {
      this.isSubmitting = true;
      const Postcard = {
        text: this.text || " ",
        attachments: this.attachments,
        topic: this.selectedTopic,
        sendFrom: this.detectMobile() ? "Phone" : "PC",
        information: this.category === "information",
        category: this.category
      };
      Api.postNewPostcard(Postcard)
        .then((res) => {
          if (res.data) this.$router.push("/success");
        })
        .catch(/* there might be a 400 error if text or user are empty */);
    },
    detectMobile() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
    getName() {
      if (this.user) {
        return this.user.first_name + " " + this.user.last_name;
      } else {
        return "Max Mustermann";
      }
    },
    getTopic() {
      return this.selectedTopic;
    },
    getCurrentDate() {
      // this formats the datetime as it is returned by the database,
      // so it can be handled the same way by following methods
      // "2022-02-13 05:06:42"
      const date = new Date();
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
      const hour = ("0" + date.getHours()).slice(-2);
      const minute = ("0" + date.getMinutes()).slice(-2);
      const second = ("0" + date.getSeconds()).slice(-2);
      return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
    },
    sendNewTopic() {
      if (this.newTopic !== "") {
        let id = this.topics.length + 1;

        const Topic = {
          topic_id: id,
          name: this.newTopic,
          approved: false,
        };
        const modalContent1 = document.getElementById("enterTopic");
        const modalContent2 = document.getElementById("infoAndNext");
        modalContent1.style.display = "none";
        modalContent2.style.display = "block";
        this.addTopic(Topic);
        this.selectedTopic = this.topics[id - 1];
      } else {
        alert("Bitte gib ein gültiges Thema an!");
      }
    },
    openNewTopicDialog() {
      var modal = document.getElementById("newTopicModal");
      modal.style.display = "block";
    },
    closeNewTopicDialog() {
      var modal = document.getElementById("newTopicModal");
      modal.style.display = "none";
    },
    closeNewTopicDialogAndGoToTopic() {
      this.closeNewTopicDialog();
      this.e1 = 2;
    },
  },
};
</script>
